/* CSS for vitnemalsportalen */
@import "../../../../../../lib/uio1/profile/css/util.less";

#hidnav a[href="#bottomnav"] {
  display: none;
}

.not-for-ansatte {
  #main
    .button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search),
  #main input[type="button"]:not(.red),
  #main input[type="submit"]:not(.submit-comment-button):not(.red),
  #main input[type="cancel"]:not(.red),
  #main
    button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red) {
    background-color: @color-primary;
    .transition(all, 0.5s, ease-in);
    &:focus,
    &:hover {
      background-color: @color-primary--dark;
    }
  }
  .header-search {
    top: 18px;
    z-index: 10;
    input[type="text"] {
      background: @color-neutral--light;
      .placeholderColor(@color-dark, @color-dark) !important;
      color: @color-neutral--dark;
    }
    .header-search-expand,
    button {
      background-color: @color-primary;
      .transition(all, 0.5s, ease-in);
      &:focus,
      &:hover {
        background-color: @color-primary--dark;
      }
    }
  }
  #head-wrapper {
    background: #ffffff;
    .head-menu .vrtx-login-manage-component .vrtx-dropdown-link {
      background: transparent
        url("../uio1/images/head-dropdown-toggle-black.png") no-repeat scroll
        100% 53%;
    }
    #head {
      .uio-app-name {
        display: none;
      }
      height: 140px;
      .head-menu {
        right: 110px;

        a {
          color: @color-neutral--dark;
        }
        .vrtx-dropdown-wrapper ul li a {
          color: @color-link !important;
        }
      }
      #header {
        img {
          position: absolute;
          top: 25px;
        }
      }
    }
  }
}
#globalnav {
  background: @color-secondary none repeat scroll 0 0;
  ul li {
    a,
    &.vrtx-active-item a {
      color: @color-light;
      &:hover,
      &:focus {
        background: @color-secondary--dark none repeat scroll 0 0;
        color: @color-light;
        margin-top: 0;
      }
    }
    &.vrtx-active-item a {
      background: @color-light none repeat scroll 0 0;
      color: @color-neutral--dark;
    }
  }
}

#left-main ul.vrtx-breadcrumb-menu li {
  &.vrtx-child a {
    color: @color-dark;
    background-color: @color-neutral--light;
  }
  a {
    &.vrtx-marked {
      background-color: @color-primary !important;
      color: @color-light;
      border: none;
    }
    &:hover,
    &:focus {
      color: @color-secondary--dark;
    }
  }
}

table th {
  font-weight: bold;
}

#bottomnav {
  display: none !important;
}

#uio-app-menu-wrapper,
#footer-wrapper {
  background: @footer-color;
}

a.vrtx-more::before,
div.vrtx-more a::before,
a.all-messages::before,
a.all-comments::before,
.footer > .vrtx-search-scope a::before,
a.more-url::before {
  background: url("../images/arrow-forward-blue.svg") no-repeat 0 0;
}
.ui-accordion.ui-widget {
  .ui-accordion-header .ui-icon {
    background: transparent url("../images/icon-chevron-right-blue.svg")
      no-repeat center left;
  }
  .ui-accordion-header.ui-accordion-header-active .ui-icon {
    background-image: url("../images/icon-chevron-down-blue.svg");
  }
}

//footer
.not-for-ansatte {
  &:not(.header-context) {
    .responsible-login .content {
      margin-left: 90px;
    }
  }

  #footer-wrapper {
    #footers {
      .kd a {
        margin-right: 2px;
        padding: 10px;
        display: block;
        border-radius: 2px;
        &:hover,
        &:focus {
          background-color: @color-light;
        }
      }
      //If javascript is turned off
      .policy {
        bottom: -30px;
        position: absolute;
        a {
          color: #ffffff;
        }
      }
    }
    background-color: @footer-color;
    *,
    a {
      color: @color-neutral--dark;
    }
    .vrtx-dropdown-component-toggled .vrtx-login-manage-link {
      background: rgba(0, 0, 0, 0) url("../uio1/images/dropdown-black.png")
        no-repeat scroll right center / 22px auto;
      margin-left: 0;
      &:focus,
      &:hover {
        background-image: url("../uio1/images/dropdown-black-hover.png");
      }
    }
  }
  .policy {
    width: 100%;
    background: @color-neutral--dark;
    ul {
      width: 970px;
      height: 100%;
      margin: 0 auto;
      li {
        background-color: @color-neutral--dark;
        margin: 0 40px 8px 0;
        padding-top: 8px;
        display: inline-block;
        height: 100%;
        &::before {
          content: "";
        }
        a {
          color: @color-light;
        }
      }
    }
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  .not-for-ansatte {
    #head-wrapper {
      background: none @color-light;
      height: 177px;
      #head {
        height: 130px;
        #header {
          img {
            padding: 0 10px 10px;
          }
        }
      }
    }
    #menu-wrapper .menu li.active {
      background: @color-primary none repeat scroll 0 0;
    }
  }
  .menu-search {
    input[type="text"] {
      border-right-width: 7em !important;
      padding-right: 0px;
    }
    button {
      background-color: @color-primary;
      .transition(all, 0.5s, ease-in);
      &:focus,
      &:hover {
        background-color: @color-primary--dark;
      }
    }
  }
}

/* Mixin */
.transition (@prop: all, @time: 1s, @ease: linear) {
  -webkit-transition: @prop @time @ease;
  -moz-transition: @prop @time @ease;
  -o-transition: @prop @time @ease;
  -ms-transition: @prop @time @ease;
  transition: @prop @time @ease;
}

/*------------------------------------*\
# COLOR
\*------------------------------------*/

// Primary, secondary etc...
@color-primary: #1684a9;
@color-primary--dark: darken(spin(@color-primary, -5), 10%);
@color-secondary: #4c4c4c;
@color-secondary--dark: darken(spin(@color-secondary, -5), 10%);
@color-link: #2771bb;

// Neutral colors
@color-neutral--dark: #2b2b2b;
@color-neutral: #949494;
@color-neutral--light: #f1f1f1;

// Suplementary colors
@color-light: #ffffff;
@color-dark: #000000;
@footer-color: @color-neutral--light;
